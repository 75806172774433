/***********feature area**********/
.feature-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;

    .media-body {
        .header-info {
            &_label {
                font-weight: 400;
                font-size: 18px;
                line-height: 34px;
                color: $body-color;
            }

            &_link {
                font-weight: 500;
                font-size: 22px;
                line-height: 28px;
                color: $white-color;
                margin-bottom: 0;

                @include xs {
                    font-size: 16px;
                    line-height: 26px;
                }

                &:hover {
                    color: $theme-color;
                }

                a {
                    color: inherit;
                }
            }   
        }
    }
}
.feature-area {
    background: #F4F4F4;
    border-radius: 15px;
    overflow: hidden;
}
@include ml {
    .feature-area {
        margin-top: -15px;
    }
}
@include lg {
    .feature-area {
        margin-top: -30px;
    }
}
@include md {
    .feature-area {
        margin-top: -45px;
    }
}

.feature-card {
    transition: 0.4s;
    position: relative;
    border: 1px solid rgba($color: #19352D, $alpha: 0.2);
    border-radius: 20px;
    padding: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-title {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 0;
        max-width: 268px;
        line-height: 1.2em;
        a {
            color: $title-color;
            &:hover {
                color: $theme-color;
            }
        }
    }
    &-text {
        flex: 1;
        margin-bottom: 0;
        max-width: 410px;
    }
    .btn-wrap {
        flex: none;
    }
    &:hover {
        background: $white-color;
        box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.05);
        border-color: $white-color;
    }
    @include md {
        display: block;
        .feature-card-title {
            max-width: none;
            margin-bottom: 20px;
        }
        .feature-card-text {
            margin-bottom: 30px;
            max-width: none;
        }
    }
    @include xs {
        padding: 30px;
        .feature-card-title {
            font-size: 24px;
        }
    }
}

/***********feature area 4**********/
.feature-wrap4 {
    position: relative;
    .feature-bg-line {
        position: absolute;
        left: 50%;
        top: 80px;
        bottom: 0;
        transform: translate(-50%, 0);
        @include ml {
            display: none;
        }
    }
}
.feature-thumb4-1 {
    border-radius: 20px;
    overflow: hidden;
    display: inline-block;
    img {
        width: 100%;
    }
}
.feature-content {
    .feature-content-title {
        font-size: 30px;
    }
    .feature-content-subtitle {
        font-size: 22px;
    }
    .feature-content-text {
        font-size: 18px;
    }
}