/* Process Area 1 ---------------------------------- */
.process-card {
  // background-color: $white-color;
  background: #2976c038;
  padding: 50px 40px 44px;
  border-radius: 20px;
  backdrop-filter: blur(4.8px);
  border: 1px solid $theme-color;
  transition: box-shadow 0.2s ease-in-out;
  &_wrapp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
  }
  &-icon {
  }
  &_number {
    font-weight: 700;
    font-size: 60.75px;
    line-height: 60.75px;
    color: rgb(255 255 255 / 21%);
    margin-bottom: 0;
  }

  .process-card-title {
    font-size: 22px;
    font-weight: 700;
    margin-top: 12px;
    margin-bottom: -0.4rem;
    color: white;
  }

  .process-card-text {
    font-size: 14px;
    font-weight: 400;
    font-family: $title-font;
    margin-bottom: 0px;
    color: white;
  }

  &:hover {
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 0 0px 22px #2976C0;
  }
}

.process-card.aos-init.aos-animate {
  transition: box-shadow 0.4s;
}

/* Process Area 2 ---------------------------------- */
.process-card-wrap2 {
  position: relative;
  &:after {
    // content: url(../img/icon/process-arrow-5-1.svg);
    content: url("~/public/assets/img/icon/process-arrow-5-1.svg");
    position: absolute;
    display: inline-block;
    right: 0;
    bottom: 0;
    transform: translate(50%, -5px);
    width: 190px;
    height: 41px;
  }
  &:nth-child(even) {
    .process-card.style2 {
      transform: translate(0, -30px);
      border-color: $theme-color2;
      .process-card_number {
        -webkit-text-stroke: 1px $theme-color2;
      }
    }
    &:after {
      bottom: auto;
      top: -20px;
      transform: translate(50%, -5px) rotateX(180deg);
    }
  }

  &:last-child {
    &:after {
      display: none;
    }
  }

  @include xl {
    &:after {
      display: none;
    }
    .process-card.style2 {
      margin: 0;
    }
  }
  @include lg {
    .process-card.style2 {
      transform: translate(0, 0px);
    }
    &:nth-child(even) {
      .process-card.style2 {
        transform: translate(0, 0px);
        border-color: $theme-color;
        .process-card_number {
          -webkit-text-stroke: 1px $theme-color;
        }
      }
    }
  }
}

.process-card.style2 {
  border: 1px solid $theme-color;
  border-radius: 20px;
  padding: 30px;
  background: transparent;
  margin: 55px 0;
  .process-card_number {
    display: inline-block;
    font-size: 60px;
    font-weight: 700;
    color: transparent;
    margin-bottom: 20px;
    -webkit-text-stroke: 1px $theme-color;
  }
  .process-card-title {
    color: $white-color;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 0;
  }
  .process-card-text {
    color: $white-color;
    font-size: 18px;
    opacity: 0.7;
    margin-bottom: 10px;
    display: block;
  }
}

.img_bg_cover{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative; /* Ensure the overlay is positioned relative to this element */
}

.img_bg_cover::before {
  content: ""; /* Required to display the pseudo-element */
  position: absolute; /* Position it absolutely within the .img_bg_cover */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Adjust the color and opacity here */
  z-index: 1; /* Make sure the overlay is on top of the background image */
}

/* Ensure content within the .img_bg_cover is visible above the overlay */
.img_bg_cover > * {
  position: relative;
  z-index: 2;
}