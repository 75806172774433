.error-area {
	.error-thumb {
		margin-bottom: 55px;
	}
	.error-content {
		margin-bottom: 45px;
	}
}
.not-found-text-wrapper {
	.text-404 {
		h4 {
			font-size: 48px;
			margin-top: -0.35em;
		}
	}
	.error-dec {
		margin-bottom: 45px;
	}
}