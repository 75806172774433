.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  // display: flex;
  background: #333;
  border-radius: 8px;
  overflow: hidden;
  width: 80%;
  max-width: 1200px;
  // height: 80%;
}

.form-section {
  // flex: 1;
  padding: 40px;
  // color: #fff;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // position: relative;
}
.form-field {
  margin-bottom: 15px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background: #444;
  color: #fff;
  font-size: 16px;
  width: 100%;

  &:focus {
    background-color: #444;
    color: white;
  }
}

.close-button {
  // position: absolute;
  top: 17%;
  right: 19%;
  background: transparent;
  border: none;
  border-radius: 50%;
  color: #fff;
  font-size: 30px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.progress-bar-container {
  // position: absolute;
  // top: 0;
  // left: 0;
  width: 100%;
  height: 5px;
  background: #555;
}

.progress-bar {
  height: 100%;
  background: $theme-color;
  filter: drop-shadow(1px 1px 8px $theme-color);
  transition: width 0.3s;
}

.label_pop {
  color: #fff;
}
.pop_font_head {
  font-size: 30px;
  line-height: 70px;
  font-weight: 700;
  color: white;
  filter: drop-shadow(0px 0px 30px #349dff);
}

.pop_sub_head {
  font-size: 20px;
  color: var(--white-color);
  line-height: 1.666;
  text-align: center;
  // margin-bottom: 60px;
}

@media screen and (min-width: 360px) and (max-width: 1024px) {
  .col-lg-4.image-section{
    display: none;
  }
  .form-section {
    padding: 20px;
}
.pop_font_head{
  font-size: 20px;
}
.pop_sub_head{
  font-size: 16px;
}
}
