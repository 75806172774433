@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";
@import "swiper/scss/thumbs";
@import "swiper/scss/free-mode";
@import "swiper/css/effect-fade";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "node_modules/react-modal-video/scss/modal-video.scss";
@import "/public/assets/sass/style.scss";
