/*-- Padding Left And Right --*/
@for $i from 1 through $space-count {
  .px-#{5 * $i} {
    padding-right: 5px *$i;
    padding-left: 5px *$i;
  }
}

/*-- Padding Top And Bottom --*/
@for $i from 1 through $space-count {
  .py-#{5 * $i} {
    padding-top: 5px *$i;
    padding-bottom: 5px *$i;
  }
}

/*-- Padding Top --*/
@for $i from 1 through $space-count {
  .pt-#{5 * $i} {
    padding-top: 5px *$i;
  }
}

/*-- Padding Bottom --*/
@for $i from 1 through $space-count {
  .pb-#{5 * $i} {
    padding-bottom: 5px *$i;
  }
}

/*-- Padding Left --*/
@for $i from 1 through $space-count {
  .pl-#{5 * $i} {
    padding-left: 5px *$i;
  }
}

/*-- Padding Right --*/
@for $i from 1 through $space-count {
  .pr-#{5 * $i} {
    padding-right: 5px *$i;
  }
}

.pb-60 {
  padding-bottom: 60px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pb-105 {
  padding-bottom: 105px;
}
.pt-105 {
  padding-top: 105px;
}
.pt-95 {
  padding-top: 95px;
}
