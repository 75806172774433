.space,
.space-top {
  // padding-top: $space;
  padding-top: 80px;
  // background: rgb(15, 15, 15);
}

.space,
.space-bottom {
  // padding-bottom: $space;
  padding-bottom: 80px;
}

.space-extra,
.space-extra-top {
  padding-top: $space-extra;
}

.space-extra,
.space-extra-bottom {
  padding-bottom: $space-extra;
}

.space-extra2,
.space-extra2-top {
  padding-top: $space-extra2;
}

.space-extra2,
.space-extra2-bottom {
  padding-bottom: $space-extra2;
}


@include md {
  .space,
  .space-top {
    // padding-top: $space-mobile;
    padding-top: 40px;
  }

  .space,
  .space-bottom {
    // padding-bottom: $space-mobile;
    padding-bottom: 40px;
  }

  .space-extra,
  .space-extra-top {
    padding-top: $space-mobile-extra;
  }

  .space-extra,
  .space-extra-bottom {
    padding-bottom: $space-mobile-extra;
  }

  .space-top-md-none {
    padding-top: 0;
  }

  .space-extra2,
  .space-extra2-top {
    padding-top: 70px;
  }

  .space-extra2,
  .space-extra2-bottom {
    padding-bottom: 70px;
  }
  .pb-105 {
    padding-bottom: 65px;
  }
  .pt-105 {
    padding-top: 65px;
}
}