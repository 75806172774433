/* Category 1 ---------------------------------- */
.category-card {
    border-radius: 6px;
    background: $white-color;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    &_content {
        max-width: 150px;
    }
    &_title {
        font-size: 20px;
        margin-top: -0.3em;
        margin-bottom: 26px;
        a {
            color: $title-color;
            &:hover {
                color: $theme-color;
            }
        }
    }
    &_icon {
        flex: none;
        align-self: end;
        position: relative;
        z-index: 1;
        &:after {
            position: absolute;
            content: '';
            border-radius: 50%;
            background-color: rgb(247, 244, 239);
            height: 60px;
            width: 60px;
            left: -30px;
            top: 0;
            z-index: -1;
        }
    }      
    .more-btn {
        font-size: 12px;
        color: $body-color;
        font-weight: 500;
        letter-spacing: 1px;
        line-height: initial;
        &:hover {
            color: $theme-color;
        }
        i {
            font-size: 14px;
            display: block;
        }
    }
}
.category-search-form {
    margin-top: 40px;
    position: relative;
    .form-control {
        padding: 14.5px 280px 14.5px 30px;
    }
    .btn {
        position: absolute;
        right: 0;
        top: 0;
        padding: 20px 50px;
    }
}
/* Category 2 ---------------------------------- */
.category-card2 {
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
    text-align: center;
    padding: 30px 30px 23px;
    &_title {
        font-size: 20px;
        margin-top: -0.3em;
        margin-bottom: 0px;
        a {
            color: $title-color;
            &:hover {
                color: $theme-color;
            }
        }
    }
    &_icon {
        display: inline-block;
        height: 66px;
        width: 66px;
        line-height: 64px;
        border-radius: 50%;
        background: $smoke-color3;
        margin-bottom: 20px;
        transition: 0.5s;
        img {
            transition: 0.5s;
        }
        &:after {
            display: none;
        }
    } 
    &:hover {
        .category-card2_icon {
            background: $theme-color;
            img {
                filter: brightness(99);
            }
        }
    }
}
.category-slider {
    transform: translateY(-50px);
    margin-bottom: -50px;
}
.category-area2 {
    padding-bottom: 100px;
    z-index: 2;
    .category-search-form {
        .form-control {
            border-color: $theme-color;
            color: $white-color;
        }
    }
}
@include sm {
    .category-search-form {
        text-align: center;
        .btn {
            position: inherit;
            margin-top: 20px;
        }
        .form-control {
            padding: 14.5px 30px;
        }
    }
}

/* Category 3 ---------------------------------- */
.category-slider3 {
    border: 1px solid $border-color;
    .slick-slide {
        border-right: 1px solid $border-color;
        @include xs {
            border-right: 0;
        }
    }
}
.category-card-item {
    text-align: center;
    padding-bottom: 18px;
    .category-card-title {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 3px;
        a {
            color: $title-color;
            &:hover {
                color: $theme-color;
            }
        }
    }
    .category-card-text {
        margin-bottom: 0;
        font-size: 14px;
        opacity: 0.6;
    }
}