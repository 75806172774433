.footer-layout2 .social-btn.style3 a {
    margin-left: 15px;
    margin-right: 0;
}
.footer-layout2 .widget-contact2 {
    margin-left: 20px;
    margin-right: 0;
}
.footer-layout2 .copyright-wrap .footer-links a:not(:last-child) {
    border-left: 0;
    padding-left: 0;
}